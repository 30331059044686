import React, {useState} from "react";
import styled from "styled-components";
// Assets
import ContactImg from "../../assets/img/website/contact.jpeg";

export default function Contact() {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    comment: ''
  });


  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
            <p className="font16">
              <b>Email: </b> <a href="mailto:prasanna@piconsult.com.au">prasanna@piconsult.com.au</a>
              <br />
              <b>Phone: </b> <a href="tel:+61410756442">+61 - 410756442</a>
            </p>
            <br/>
            <p>Reach out to us through contact form</p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form action="https://docs.google.com/forms/d/e/1FAIpQLScKOw3Su-BLWRLHutpkHcQJirmdQf7tsUONIS7HlyQOjyyIrg/formResponse"
                  method="POST"
                  target="_blank" >
                <label className="font13">Name:</label>
                <input type="text" id="name" name="entry.2005620554" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} className="font20 extraBold" />
                <label className="font13">Email:</label>
                <input type="text" id="email" name="entry.1045781291" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} className="font20 extraBold" />
                <label className="font13">Phone:</label>
                <input type="text" id="phone" name="entry.1166974658" value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} className="font20 extraBold" />
                <label className="font13">Comment:</label>
                <textarea rows="4" cols="50" type="text" id="message" name="entry.839337160" value={formData.comment} onChange={(e) => setFormData({ ...formData, comment: e.target.value })} className="font20 extraBold" />
                <button type="submit" style={{ width:'200px', border: '1px solid rgb(118, 32, 255)', backgroundColor : 'rgb(118, 32, 255)', padding: '15px', outline: 'none', color: 'rgb(255, 255, 255)' }} className="pointer animate radius8">Submit</button>
              </Form>
              
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px", paddingLeft: "40px" }}>
                  <img src={ContactImg} alt="office" style={{maxWidth:'400px'}} className="radius6" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;