import React from "react";
import styled from "styled-components";

export default function AboutUs() {
  return (
    <Wrapper id="aboutUs">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">About Us</h1>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <WrapperBox className="whiteBg radius8 shadow">
                <h3 className="font20 extraBold">Our Mission</h3>
                <p className="font13" style={{ padding: "30px 0" }}>
                Our mission is simple: to add value through our extensive experience. 
                We are not here to revolutionize the world, but to offer tailored solutions that truly meet your needs. 
                Understanding that a one-size-fits-all approach doesn't work, we bring a diverse set of skills and strategies to the table, ensuring your requirements are met with precision.
                </p>
                
                
            </WrapperBox>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <WrapperBox className="whiteBg radius8 shadow">
                    <h3 className="font20 extraBold">Why Us</h3>
                    <p className="font13" style={{ padding: "30px 0" }}>
                    Choosing us means choosing quality over quantity. 
                    We believe in the power of precision and personalized service. 
                    Instead of flooding you with countless resumes, we take the time to understand your unique needs and provide a curated selection of high-quality candidates. 
                    Our goal is to help you find the perfect fit efficiently and effectively.
                    </p>
                    
                    
                </WrapperBox>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <WrapperBox className="whiteBg radius8 shadow">
                    <h3 className="font20 extraBold">Our Investment in Employees</h3>
                    <p className="font13" style={{ padding: "30px 0" }}>
                    We are passionate about nurturing talent and investing in our employees. 
                    By offering opportunities that align with their passions and skills, we create an environment where our team members can thrive and grow. 
                    Our commitment to continuous learning and professional development ensures that our employees are always equipped to meet the evolving demands of the industry.
                    </p>
                    
                    
                </WrapperBox>
            </div>
            
          </div>
         
        </div>
      </div>
    </Wrapper>
  );
}

const WrapperBox = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;