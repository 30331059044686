import React from "react";
import styled from "styled-components";
import Recruitment from "../../assets/img/website/Recruitment.jpeg";
import Training from "../../assets/img/website/Training.jpeg";
import Partnership from "../../assets/img/website/Partnership.jpeg";

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Services</h1>
          </HeaderInfo>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={Recruitment} style={{maxWidth:'425px', maxHeight:'600px'}} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h2 className="font40 extraBold">Recruitment</h2>
              <p className="font16">
              Finding the right resource is almost 50% of your solution; the remaining 50% is what they deliver. 
              We understand this balance intimately. That's why we don't send you CVs without a clear understanding of the role you're looking to fill. 
              Our approach reduces turnaround time and ensures that the right skilled people are on the floor, ready to contribute effectively from day one.
              </p>
             
            </AddRight>
          </Advertising>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddRight>
              <h2 className="font40 extraBold">Training</h2>
              <p className="font16">
              We offer comprehensive training sessions tailored to your organization's needs. Our training services cover:
              </p>
              <ul>
                <li><b>People Development:</b> Enhancing interpersonal and leadership skills to build stronger teams.</li>
                <li><b>Professional Development:</b> Focusing on career growth and professional skills to keep your workforce competitive.</li>
                <li><b>Technical Development:</b> Providing in-depth technical training to ensure your team is up-to-date with the latest industry standards and technologies.</li>
              </ul>
             
            </AddRight>
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={Training} style={{maxWidth:'425px', maxHeight:'600px'}} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
          </Advertising>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={Partnership} style={{maxWidth:'425px', maxHeight:'600px'}} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h2 className="font40 extraBold">Partnership</h2>
              <p className="font16">
              We believe in building strong partnerships rather than merely providing resources. 
              By working closely together, we aim to foster mutual growth and success. 
              Our partnership approach ensures that we grow alongside you, offering continued support and value beyond just filling positions.
              </p>
             
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
