import React from "react";
import styled from "styled-components";

export default function EmployersAndEmployees() {
  return (
    <Wrapper id="EmployersAndEmployees">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Employers & Employees</h1>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
           
            <TableBox>
                <WrapperBox className="whiteBg radius8 shadow">
                <div style={{ margin: "30px 0" }}>
                    <h4 className="font30 extraBold">Employers</h4>
                    <p>We understand the challenges of finding the right talent for your organization. 
                        Our bespoke recruitment process is designed to provide you with candidates who are not only qualified but also a perfect cultural fit for your team. 
                        Here's how we do it:
                    </p>
                    <ul>
                        <li><b>Needs Assessment:</b> We take the time to understand your specific requirements and organizational culture.</li>
                        <li><b>Candidate Sourcing:</b> Utilizing our extensive network and advanced sourcing techniques, we find the best candidates for your needs.</li>
                        <li><b>Screening and Interviews:</b> We conduct thorough screenings and interviews to ensure the candidates we present meet your expectations.</li>
                        <li><b>Ongoing Support:</b> Even after placement, we offer continued support to ensure successful integration and performance.</li>
                    </ul>
                </div>
                </WrapperBox>
            </TableBox>
          </TablesWrapper>
          <TablesWrapper>
          <TableBox>
                <WrapperBox className="whiteBg radius8 shadow">
                <div style={{ margin: "30px 0" }}>
                    <h4 className="font30 extraBold">Employees</h4>
                    <h6 className="font18">Your Career Journey Starts Here</h6>
                    <p>At our core, we believe in aligning your passions and skills with the right opportunities. Our extensive experience in the IT industry enables us to understand your career aspirations and help you navigate the job market effectively.</p>
                    <br/>
                    <b>Personalized Support</b>
                    <p>We offer personalized support to each job seeker, ensuring that your unique talents and goals are recognized and matched with the perfect role. Our process includes:</p>
                    <ul>
                        <li><b>Career Consultation: </b>Understanding your career goals, skills, and aspirations to find the best fit.</li>
                        <li><b>Resume Optimization: </b>Helping you craft a standout resume that highlights your strengths and achievements.</li>
                        <li><b>Interview Preparation:</b> Providing you with tips and practice sessions to boost your confidence and performance.</li>

                    </ul>
                    <br/>
                    <b>Continuous Learning and Growth</b>
                    <p>We believe in continuous learning and professional growth. Our training programs are designed to keep you updated with the latest industry trends and technologies. Whether it's people development, professional development, or technical development, we have you covered.</p>
                    <br/>
                    <b>Join Our Talent Pool</b>
                    <p>Becoming a part of our talent pool means you’ll have access to exclusive job opportunities and continuous support. We are committed to helping you find not just a job, but a career that you are passionate about.</p>
                </div>
                </WrapperBox>
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 100%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;
const WrapperBox = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;





